@import "~@/styles/variables";





































































































.actions {
  .action {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500 !important;

    &:first-child {
      padding-left: 0 !important;
    }

    .icon-check {
      position: relative;
      top: -2px;
    }
  }
}

@import "~@/styles/variables";












































































































































// XXX reimplement this button
.add-address-button {
  position: relative;
  z-index: 2;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  padding-bottom: 0.1em;
  margin: 0 0.5em 0 auto;
  line-height: 1;
  font-size: 2rem;
  margin-top: -0.75em;
  margin-bottom: -0.75em;
  text-align: center;
}
